import serverDateTime from 'src/serverDateTime'

let KlickartPublicDelay = () => {
  const OPTIONS_ATTR = 'klickart-delay-options'
  const SECONDS = 1000


  let timeDelay = (element, time) => {

    setTimeout( () => {
      element.classList.remove('klickart-hide')
      element.classList.add('klickart-show')

    }, time * SECONDS)
  }

  let dateDelay = (element, datetimeString, timezone) => {
    let selectedDate = new Date(datetimeString+timezone)
    let currentDate = serverDateTime().getTime()

    let diff = selectedDate - currentDate
    const validDateTimeRange = 2073600000

    if (diff > validDateTimeRange)return

    if (diff < 0)diff = 0

    setTimeout( () => {
      element.classList.remove('klickart-hide')
      element.classList.add('klickart-show')

    }, diff)
  }

  let delaysElements = document.body.querySelectorAll(`[data-${OPTIONS_ATTR}]`)
  delaysElements.forEach( (element) => {
    let options = JSON.parse(element.getAttribute(`data-${OPTIONS_ATTR}`))
    if (!options.enabled)return
    element.classList.add('klickart-hide')

    switch (options.selectedType) {
      case 'date':
        dateDelay(element, options.dateTime, options.timezone)
        break
      default:
        timeDelay(element, options.time)
        break
    }
  })

}


export default KlickartPublicDelay
