const htmlParser = () => {
  const replaceHtml = (queryStringValues, element) => {
    let outerHTML = element.outerHTML;

    queryStringValues.forEach((item) => {
      const param = "{:" + item.key + "}";
      const encodedParam = "%7B:" + item.key + "%7D";
      const paramsToReplace = [param, encodedParam];

      paramsToReplace.forEach((paramToReplace) => {
        if (outerHTML.includes(paramToReplace)) {
          const regExp = new RegExp(paramToReplace, "g");
          outerHTML = outerHTML.replace(regExp, item.value);
        }
      });
    });

    if (outerHTML !== element.outerHTML) {
      element.outerHTML = outerHTML;
    }
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryStringElements = document.body.querySelectorAll('*');
  const queryStringValues = [];

  urlSearchParams.forEach((value, key) => {
    queryStringValues.push({ key, value });
  });

  queryStringElements.forEach((element) => {
    replaceHtml(queryStringValues, element);
  });
};

export default htmlParser;
