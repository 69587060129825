import "lazyload"

let lazyLoadImages = () => {
  var images = document.getElementsByTagName('img');
  [].forEach.call(images, function (img) {
    img.classList.add('lazyload');
  });

  lazyload();
};

export { lazyLoadImages }
