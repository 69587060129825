
let smoothScroll = () => {

  let calculateLength = (section) => {
    if (section === null)return
    return section.getBoundingClientRect().top
  }

  let elements = document.body.querySelectorAll('a[href*="#section"]:not([href="#"]):not([klickart-edit-link-type="popup"])')
  elements.forEach(element => {
    let partHref = element.href.split('#')
    let sectionId = partHref[1]
    let section = document.body.querySelector(`#${sectionId}`)
    if (!section) return true
    let length = calculateLength(section)
    if (length == undefined) return true

    element.addEventListener('click', function(){
      window.scrollTo({
        top: length,
        behavior: 'smooth'
      })
    })
  })
}

export default smoothScroll
