const KlickartPublicWhatsapp = () => {
  const whatsappLinkElements = [
    ...document.querySelectorAll('[klickart-edit-link-type="whatsapp"]'),
  ];

  if (!/Mobi/.test(navigator.userAgent)) {
    whatsappLinkElements.forEach((element) => element.style.display = "none");
  }

  return whatsappLinkElements.forEach((linkElement) =>
    linkElement.setAttribute(
      "href",
      `whatsapp://send?text=${linkElement.getAttribute(
        "klickart-edit-link-href-whatsapp"
      )}-${window.location.href}`
    )
  );
};
export default KlickartPublicWhatsapp;
