var klickartPerformantPhoneNumber = function () {
  var LINK_TYPE = '[klickart-edit-link-type="phoneNumber"]';
  var COUNTRY_CODE = "klickart-edit-link-href-country-code";
  var AREA_CODE = "klickart-edit-link-href-area-code";
  var PHONE_NUMBER = "klickart-edit-link-href-phone-number";
  var LINK_PREFIX = "tel:";

  function buildLinkPhoneNumber(element) {
    var countryCode = element.getAttribute(COUNTRY_CODE);
    var areaCode = element.getAttribute(AREA_CODE);
    var phoneNumber = element.getAttribute(PHONE_NUMBER);

    element.setAttribute(
      "href",
      LINK_PREFIX + "+" + countryCode + "-" + areaCode + "-" + phoneNumber
    );
  }

  function buildPlainPhoneNumber(element) {
    element.setAttribute("href", "#");
  }

  function buildPhoneNumber(element) {
    if (/Mobi/.test(navigator.userAgent)) {
      return buildLinkPhoneNumber(element);
    }

    return buildPlainPhoneNumber(element);
  }

  var linkElementList = document.querySelectorAll(LINK_TYPE);
  var phoneNumberLinkElements = Array.from(linkElementList);

  phoneNumberLinkElements.forEach(function (element) {
    buildPhoneNumber(element);
  });
};

export default klickartPerformantPhoneNumber;
