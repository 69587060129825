let serverDateTime = () => {

  let basePath = document.querySelector('meta[name="klickart:url"]').getAttribute('content')
  let url = 'https:' + basePath + '/public/server/date_time'

  let xhttp = new XMLHttpRequest()
  xhttp.open("GET", url, false)
  xhttp.send()

  if(xhttp.status !== 200){
    console.error({status: xhttp.status, text:xhttp.statusText, message:'Unable to get server datetime'})
    return null
  }

  let response = JSON.parse(xhttp.response)
  let serverTime = new Date(response.date_time)
  return serverTime

}

export default serverDateTime
