
let loadGoogleFonts = () => {
  const font_map = {
    'Roboto Condensed': 'Roboto Condensed:400,700:latin',
    'Source Sans Pro': 'Source Sans Pro:400,600,400italic,700italic,700:latin',
    'Dosis': 'Dosis:300,500,700',
    'Lato': 'Lato:100,300,400,700,900,100italic,300italic,400italic,700italic,900italic',
    'Lora': 'Lora:400,700,400italic,700italic',
    'Montserrat': 'Montserrat:300,400,500,700',
    'Oswald': 'Oswald:300,400,700',
    'Open Sans': 'Open Sans:300,400,600,700,300italic,400italic,600italic,700italic',
    'Open Sans Condensed': 'Open Sans Condensed:300,700',
    'Roboto': 'Roboto:n1,n3,n4,n5,n7,n9,i1,i3,i4,i5,i7,i9',
    'Raleway': 'Raleway:300,400,700,300italic,400italic,700italic',
    'Nunito': 'Nunito:wght@200,400,700,800',
    'Vollkorn': 'Vollkorn:ital,wght@0,400,500,600,700,800,900'
  }
  let WebFontConfig = {
    google: {
      families: [
        'Roboto Condensed:400,700:latin',
        'Source Sans Pro:400,600,400italic,700italic,700:latin',
        'Dosis:300,500,700',
        'Lato:100,300,400,700,900,100italic,300italic,400italic,700italic,900italic',
        'Lora:400,700,400italic,700italic',
        'Montserrat:300,400,500,700',
        'Oswald:300,400,700',
        'Open Sans:300,400,600,700,300italic,400italic,600italic,700italic',
        'Open Sans Condensed:300,700',
        'Roboto:n1,n3,n4,n5,n7,n9,i1,i3,i4,i5,i7,i9',
        'Raleway:300,400,700,300italic,400italic,700italic',
        'Nunito:wght@200,400,700,800',
        'Vollkorn:ital,wght@0,400,500,600,700,800,900'
      ]
    },
    fontinactive: function (familyName, fvd) { console.warn(`Unable to load font ${familyName}:${fvd}`) },
  }
  let lazyLoadFonts = () => {
    let fonts = []         // array com as fontes retornadas
    let detectedFonts = {} // fontes encontradas sao armazenadas aqui para evitar conflito

    let elements = document.body.querySelectorAll('h1, h2, h3, h4, h5, p, span, button, div, b')

    elements.forEach(element => {
      let style = element.getAttribute('style')
      if (style === null) return

      try {
        if (style.search('font-family') >= 0) {
          let font = style.split('font-family: ')[1]
          if (font.search(',') >= 0) {
            font = font.split(',')[0]
          } else {
            font = font.replace(';', '')
          }
          if (font_map[font] !== undefined && detectedFonts[font] === undefined) {
            detectedFonts[font] = font_map[font]
          }
        }
      } catch (e) {
        console.warn({ element, message: e.message })
        return
      }
    })

    for (let font in detectedFonts) {
      fonts.push(detectedFonts[font])
    }
    return fonts
  }


  let fonts = lazyLoadFonts()
  if(fonts.length === 0)return

  WebFontConfig.google.families = fonts

  var wf = document.createElement('script')
  wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js'
  wf.async = 'true'
  var s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(wf, s)
}

export { loadGoogleFonts }
