const klickartIframePopup = () => {
  const canvas = document.createElement("div");
  const background = document.createElement("div");
  canvas.setAttribute("klickart-popups-canvas", "");
  canvas.style.display = "none";
  background.classList.add("popup-background");
  canvas.appendChild(background);

  document.body.appendChild(canvas);

  const popupsTarget = document.querySelectorAll('[klickart-edit-link-target="popup"]');
  const popupBackground = document.querySelector(".popup-background");
  const popupCanvas = document.querySelector("[klickart-popups-canvas]");
    
  generateClosePopupFunction = function () {
    return function () {
      popupBackground.style.display = "none";
      popupCanvas.style.display = "none";
      popupBackground.innerHTML = "";
      document.body.style.overflow = "";
    };
  };
  
  popupsTarget.forEach(function (popupTarget) {
    var popupHref = popupTarget.getAttribute("href").replace("#", "");
    var closePopupFunction = generateClosePopupFunction();

    popupTarget.addEventListener("click", function () {
        event.preventDefault();

        popupCanvas.style.display = "flex";
        popupBackground.style.display = "block";
;
        popupBackground.innerHTML = `<div class="iframe-popup"><iframe width="600" height="332.5" src="${popupHref}?autoplay=1" title="Klickart YouTube embed video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`;
        popupBackground.onclick = closePopupFunction;
        
        document.body.style.overflow = "hidden";
    });
  });
};

window.addEventListener("load", function () {
  klickartIframePopup();
});